
import Page from "@/core-ui/Page.vue";
import ButtonSelect from "@/core-ui/forms/components/ButtonSelect.vue";
import DataGrid from "@/core-ui/data-grid/components/DataGrid.vue";
import { ActionOn, DataGridAction, toDisplayColumns, useDataGridModel } from "@/core-ui/data-grid/compositions";
import { createModelProps } from "@/models/deployments.model";
import { computed, defineComponent } from "vue";
import { ClusterUUIDKey, useClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import DeploymentDetailsPanel from "@/components/deployments/DeploymentDetailsPanel.vue";
import { useRouter } from "vue-router";
import { clusterApi } from "@/cluster-ui/services/apiClient";
import { storageService } from "@/core-ui/data-grid/services";
import authStore from "@/stores/authStore";

export default defineComponent({
    components: { DeploymentDetailsPanel, DataGrid, Page, ButtonSelect },
    props: {
        clusterUuid: String,
    },
    setup() {
        const clusterKey: ClusterUUIDKey = useClusterUUIDKey({
            key: "deployment",
        });
        const router = useRouter();
        const selectedMode = computed<string>({
            get: () => {
                const v = storageService.getStr("deployments_view", "History");
                if (v == "Current") {
                    router.push("/deployments");
                }
                return v;
            },
            set: (v) => storageService.setStr("deployments_view", v),
        });
        const model = useDataGridModel(
            createModelProps(clusterKey, {
                get mode() {
                    return selectedMode.value;
                },
            }),
        );

        const actionDisabledMsgs = {
            connectivityProblem: (request: string, problem: string | undefined) => `${request} is blocked. (${problem})`,
        };

        const hiddenKeys = ['status'];

        return {
            model,
            selectedMode,
            get hiddenKeys () {
               return hiddenKeys;
            },
            get displayColumns() {
                return toDisplayColumns(null, model.columns, hiddenKeys);
            },
            actions: [
                {
                    on: ActionOn.None,
                    key: "add",
                    icon: "raicon-add",
                    label: `New Deployment`,
                    aid: `new-deployment`,
                    permitted: authStore.isMLEngineer,
                    disabled: () =>
                        "true".localeCompare(clusterApi.inferenceAvailabilityStatus) != 0 &&
                        actionDisabledMsgs.connectivityProblem(
                            "Creating an inference workload",
                            clusterApi.inferenceAvailabilityStatus,
                        ),
                    func: () => router.push("/deployments/new") as any,
                },
            ] as DataGridAction[],
        };
    },
});
